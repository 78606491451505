























































import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import { useRouter, defineComponent } from '@nuxtjs/composition-api';
import { getStoryblokLink } from '~/modules/storyblok/helpers/getStoryblokLink';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

export default defineComponent({
  name: 'InfoTileBackgroundImage',
  components: { SfImage },
  props: {
    blok: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();

    const pushToUrl = () => {
      const isStoryLink = props.blok.link.linktype === 'story';
      const url = resolveUrl(props.blok?.link.cached_url as string);
      if (!isStoryLink) {
        window.open(url, '_blank');
      } else {
        router.push(`/${decodeURIComponent(url)}`);
      }
    };

    const backgroundImage = `url(${props.blok.backgroundImage.filename})`;
    const background = props.blok?.overlayColor?.color;

    return {
      pushToUrl,
      addBasePath,
      backgroundImage,
      background,
      getStoryblokLink,
      resolveUrl,
    };
  },
});
